<template>
  <ion-page>
    <Header></Header>
    <ion-content :fullscreen="true">

      <section class="md-section" id="legal-text">
        <div class="container">
          <h1 class="text-center" style="padding-bottom: 1rem"><strong>Devoluciones</strong></h1>

          <div class="invisible-box">
            <h3>Información General</h3>
            <p>Ofrecemos reembolso y/o cambio dentro de los primeros 30 días de tu compra. Si han transcurrido 30 días desde tu compra, no se te ofrecerá un reembolso y/o cambio de ningún tipo.</p>

            <h5>Elegibilidad para reembolsos y cambios</h5>
            <p>
              <ul>
                <li>Tu artículo debe estar sin usar y en las mismas condiciones en que lo recibió.</li>
                <li>El artículo debe estar en el embalaje original.</li>
                <li>Para completar tu devolución, requerimos un recibo o comprobante de compra.</li>
                <li>Solo se pueden reembolsar los artículos de precio regular; los artículos de venta no se pueden reembolsar.</li>
                <li>Si el artículo en cuestión fue marcado como un regalo cuando fue comprado y se envió directamente a ti, recibirás un crédito de regalo por el valor de tu devolución.</li>
              </ul>
            </p>

            <h5>Cambios</h5>
            <p>Solo reemplazamos los artículos si están defectuosos o dañados. Si necesitas cambiarlo por el mismo artículo, envíanos un email a (Agrega dirección de correo electrónico relevante) y envía tu artículo a:</p>
            <p>Calle Marie Curie con Esq., Calle Severo Ochoa, 11130 Chiclana de la Frontera, Cádiz</p>

            <p>Reembolsos atrasados o faltantes</p>
            <ul>
              <li> Si aún no has recibido un reembolso, primero verifica otra vez tu cuenta bancaria. Luego, comunícate con la compañía de tu tarjeta de crédito. Puede tomar algún tiempo antes de que se publique oficialmente tu reembolso.</li>
              <li> Si has hecho todo esto y aún no has recibido tu reembolso, comunícate con nosotros al (email y/o número de teléfono).</li>
            </ul>

            <h5>Envío</h5>
            <p>
              <ul>
                <li>Por favor, no devuelvas el producto al fabricante. Debe enviarse a la siguiente dirección: Calle Marie Curie con Esq., Calle Severo Ochoa, 11130 Chiclana de la Frontera, Cádiz.</li>
                <li>Usted será responsable de pagar sus propios costos de envío para devolver su artículo.</li>
                <li>Los costos de envío no son reembolsables. Si recibes un reembolso, el costo del envío de devolución se deducirá de tu reembolso.</li>
                <li>Dependiendo de dónde vivas, el tiempo que tarde tu producto cambiado en llegar a ti puede variar.</li>
                <li>Por favor, nota que no podemos garantizar que recibiremos tu artículo devuelto.</li>
              </ul>
            </p>
          </div>

          <div class="invisible-box">
            <h3>Desistimiento</h3>
            <p>Descargar <a href="https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/Modelo%20de%20Desentimiento.pdf?alt=media" target="_blank">Formulario de Desistimiento</a></p>
          </div>
        </div>
      </section>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'Returns',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
  }
});
</script>

<style scoped>

</style>